import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AltArrowForward } from '@airhelp/icons';
import { Box, Button, Text, useBreakpointValue } from '@chakra-ui/react';
import cookie from 'react-cookies';
import { NOTIFICATION_DISMISSED_COOKIE } from 'config/cookies';
import { PAGE_PATHS } from 'config/routes';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { createCookieOptions } from 'utils/cookies';
import { isRtl } from 'utils/textDirection/isRtl';
import ChipGroup, { type BaseChipItem } from 'elements/ChipGroup/ChipGroup';

export enum MyAccountTab {
  DETAILS = 'details',
  NOTIFICATIONS = 'notifications',
  BENEFITS = 'benefits',
  SPECIAL_OFFER = 'special-offer',
  BILLING = 'billing',
}

export enum MyAccountEvents {
  DETAILS = 'details',
  NOTIFICATIONS = 'notifications',
  BENEFITS = 'my benefits',
  SPECIAL_OFFER = 'special offer',
  BILLING = 'billing',
}

interface TabItem extends BaseChipItem {
  eventName: MyAccountEvents;
  key: MyAccountTab;
  label: string;
  path: string;
}

const MyAccountView = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userProfile } = useFetchMyProfile();

  const { trackCtaClick, trackPageInteractions } = useTrackEvent();

  const showSpecialOffer = userProfile?.policy?.membership?.isEssential;
  const showPaymentManagement =
    userProfile?.policy.membership?.showPaymentManagementPage;

  const onTabClick = (tab: TabItem) => {
    trackPageInteractions('clicked', tab.eventName);

    navigate(generatePath(tab.path));
  };

  const tabs: TabItem[] = useMemo(() => {
    const tabsArray: TabItem[] = [];

    if (showSpecialOffer) {
      tabsArray.push({
        eventName: MyAccountEvents.SPECIAL_OFFER,
        key: MyAccountTab.SPECIAL_OFFER,
        label: t('common.special_offer'),
        isActive: location.pathname.includes(MyAccountTab.SPECIAL_OFFER),
        path: PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE,
        showNotification: !cookie.load(NOTIFICATION_DISMISSED_COOKIE),
      });
    }

    tabsArray.push(
      {
        eventName: MyAccountEvents.DETAILS,
        key: MyAccountTab.DETAILS,
        label: t('common.details'),
        isActive: location.pathname.includes(MyAccountTab.DETAILS),
        path: PAGE_PATHS.MY_ACCOUNT.DETAILS_PAGE,
        showNotification: false,
      },
      {
        eventName: MyAccountEvents.BENEFITS,
        key: MyAccountTab.BENEFITS,
        label: t('common.plan_and_benefits'),
        isActive: location.pathname.includes(MyAccountTab.BENEFITS),
        path: PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE,
        showNotification: false,
      },
    );

    if (showPaymentManagement) {
      tabsArray.push({
        eventName: MyAccountEvents.BILLING,
        key: MyAccountTab.BILLING,
        label: t('payment_management.payment'),
        isActive: location.pathname.includes(MyAccountTab.BILLING),
        path: PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE,
        showNotification: false,
      });
    }

    tabsArray.push({
      eventName: MyAccountEvents.NOTIFICATIONS,
      key: MyAccountTab.NOTIFICATIONS,
      label: t('common.notifications'),
      isActive: location.pathname.includes(MyAccountTab.NOTIFICATIONS),
      path: PAGE_PATHS.MY_ACCOUNT.NOTIFICATIONS_PAGE,
      showNotification: false,
    });

    return tabsArray;
  }, [locale, location, showSpecialOffer, showPaymentManagement, t]);

  const handleBackButtonClick = () => {
    trackCtaClick('back', 'my account page');

    navigate(PAGE_PATHS.HOME_PAGE);
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box width="100%">
      {isDesktop ? (
        <Button
          variant="link"
          leftIcon={
            <AltArrowForward
              transform={`rotate(${isRtl(locale) ? '0' : '180deg'})`}
            />
          }
          onClick={handleBackButtonClick}
          data-testid="back-button"
        >
          {t('common.back')}
        </Button>
      ) : null}
      <Text fontSize="xl" fontWeight="700">
        {t('my_account.header')}
      </Text>

      <ChipGroup
        my={4}
        pe={4}
        items={tabs}
        dataTestId="account-details-tab"
        onItemClick={(item) => {
          onTabClick(item);
          if (item.showNotification) {
            cookie.save(
              NOTIFICATION_DISMISSED_COOKIE,
              Date.now(),
              createCookieOptions(),
            );
          }
        }}
      />
      <Outlet />
    </Box>
  );
};

export default MyAccountView;
