import { useClaimCommunicationHistorySuspense } from '@airhelp/webapp/react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'date-fns';
import * as VerticalStepTracker from 'components/VerticalStepTracker';
import Drawer from 'components/Drawer';

export function History() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const { data } = useClaimCommunicationHistorySuspense({
    params: { id: Number(id) },
  });
  const claimHistory = data.messages.slice().reverse();
  const shortClaimHistorySize = 3;
  const initialClaimHistory = claimHistory.slice(-1 * shortClaimHistorySize);
  const claimHistorySize = claimHistory.length;
  const isClaimHistoryLong = claimHistorySize > shortClaimHistorySize;

  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 8,
      }}
    >
      <VerticalStepTracker.Root
        extendedBorder={isClaimHistoryLong ? 'top' : undefined}
      >
        {initialClaimHistory.map((stage) => (
          <CommunicationHistoryListItem
            key={stage.id}
            subject={stage.subject}
            content={stage.textContent}
            createdAt={stage.createdAt}
          />
        ))}
      </VerticalStepTracker.Root>
      {isClaimHistoryLong ? (
        <Button
          variant="secondary"
          size="s"
          onClick={onOpen}
          mt={4}
          data-testid="view-status-details-button"
        >
          View Status Details
        </Button>
      ) : null}
      <Drawer header="Claim history" isOpen={isOpen} onClose={onClose}>
        <Box w="full" ps={4} pe={6}>
          <VerticalStepTracker.Root>
            {claimHistory.map((stage) => (
              <CommunicationHistoryListItem
                key={stage.id}
                subject={stage.subject}
                content={stage.textContent}
                createdAt={stage.createdAt}
              />
            ))}
          </VerticalStepTracker.Root>
        </Box>
        <Box p={4} pb={6}>
          <Button onClick={onClose} w="full">
            Close
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

function CommunicationHistoryListItem({
  subject,
  content,
  createdAt,
}: {
  subject: string;
  content: string;
  createdAt: Date;
}) {
  return (
    <VerticalStepTracker.Step
      header={subject}
      subheader={formatDate(createdAt, 'dd/MM/yyyy HH:mm')}
      state="upcoming"
    >
      <VerticalStepTracker.StepContent>
        <Box
          fontSize="sm"
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </VerticalStepTracker.StepContent>
    </VerticalStepTracker.Step>
  );
}
