import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'date-fns';
import * as VerticalStepTracker from 'components/VerticalStepTracker';
import Drawer from 'components/Drawer';
import { useClaimState } from 'hooks/useClaimState';

export function Status() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const {
    history: unorderedClaimHistory,
    track: claimTrack,
    isClosed: isClaimClosed,
    isSuccessful: isClaimSuccessful,
  } = useClaimState(id);
  const claimHistory = unorderedClaimHistory.slice().reverse();

  const shortClaimHistorySize = 3;
  const initialClaimHistory = claimHistory.slice(-1 * shortClaimHistorySize);
  const initialClaimHistorySize = initialClaimHistory.length;
  const claimHistorySize = claimHistory.length;
  const isClaimHistoryLong = claimHistorySize > shortClaimHistorySize;
  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 8,
      }}
    >
      <VerticalStepTracker.Root
        extendedBorder={isClaimHistoryLong ? 'top' : undefined}
      >
        {initialClaimHistory.map((stage, idx) => {
          return (
            <ClaimStatusListItem
              key={stage.completedAt.toISOString()}
              historyIndex={idx}
              historySize={initialClaimHistorySize}
              claimTrack={claimTrack}
              claimState={stage.state}
              completedAt={stage.completedAt}
              claimSuccessful={isClaimSuccessful}
              claimClosed={isClaimClosed}
            />
          );
        })}
      </VerticalStepTracker.Root>
      {isClaimHistoryLong ? (
        <Button
          variant="secondary"
          size="s"
          onClick={onOpen}
          mt={4}
          data-testid="view-status-details-button"
        >
          View Status Details
        </Button>
      ) : null}
      <Drawer header="Status Details" isOpen={isOpen} onClose={onClose} p={4}>
        <VerticalStepTracker.Root>
          {claimHistory.map((stage, idx) => {
            return (
              <ClaimStatusListItem
                key={stage.completedAt.toISOString()}
                claimState={stage.state}
                completedAt={stage.completedAt}
                claimTrack={claimTrack}
                historyIndex={idx}
                historySize={claimHistorySize}
                claimSuccessful={isClaimSuccessful}
                claimClosed={isClaimClosed}
              />
            );
          })}
        </VerticalStepTracker.Root>
      </Drawer>
    </Box>
  );
}

function ClaimStatusListItem({
  completedAt,
  claimState,
  claimTrack,
  historyIndex,
  historySize,
  claimSuccessful,
  claimClosed,
}: {
  completedAt: Date;
  claimState: string;
  claimTrack: string;
  historyIndex: number;
  historySize: number;
  claimSuccessful: boolean;
  claimClosed: boolean;
}) {
  const { t } = useTranslation();
  const isUpcoming =
    historyIndex === historySize - 1 && !claimSuccessful && !claimClosed;
  const isCompleted =
    historyIndex < historySize - 1 ||
    (claimSuccessful && historyIndex === historySize - 1);
  return (
    <VerticalStepTracker.Step
      // @ts-expect-error -- some fast_track translations are not available for all claim states
      // and the union will error about that, but we fall back to regular_track translations
      header={t([
        `claims.states.${claimTrack}.${claimState}_header`,
        `claims.states.regular_track.${claimState}_header`,
      ])}
      subheader={formatDate(completedAt, 'dd/MM/yyyy HH:mm')}
      state={isUpcoming ? 'upcoming' : isCompleted ? 'completed' : 'failed'}
    >
      {isUpcoming ? (
        <VerticalStepTracker.StepBadge>Up Next</VerticalStepTracker.StepBadge>
      ) : null}
    </VerticalStepTracker.Step>
  );
}
