import {
  Text,
  Flex,
  Box,
  Image,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useClaimCommunicationHistorySuspense } from '@airhelp/webapp/react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'date-fns';
import claimCompensationBg from 'assets/images/claim_compensation.png';
import noCompensationBg from 'assets/images/buka_looking.png';
import * as VerticalStepTracker from 'components/VerticalStepTracker';
import Drawer from 'components/Drawer';

function TotalCompensation({
  perPassengerCompensation,
  totalCompensation,
}: {
  perPassengerCompensation: string;
  totalCompensation: string;
}) {
  return (
    <Box textAlign="center">
      <Text
        fontSize="xs"
        color="primary.900"
        fontWeight="bold"
        textTransform="uppercase"
        mb={{
          base: 3,
          md: -2,
        }}
      >
        Your compensation
      </Text>
      <Text
        fontWeight="bold"
        color="primary.500"
        fontSize={{
          base: '36px',
          md: '5xl',
        }}
        data-testid="summary-total-compensation"
      >
        {totalCompensation}
      </Text>
      <Text color="greyscale.600" fontWeight="normal" fontSize="sm">
        Per passenger: {perPassengerCompensation}
      </Text>
    </Box>
  );
}

function DisabledCompensation() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const { data } = useClaimCommunicationHistorySuspense({
    params: { id: Number(id) },
  });

  const claimHistory = data.messages.slice().reverse();

  return (
    <Box textAlign="center" maxW="80%">
      <Text
        fontSize="xs"
        color="primary.900"
        fontWeight="bold"
        textTransform="uppercase"
        mb={1}
      >
        No compensation
      </Text>
      <Text fontSize="sm" color="primary.900" fontWeight="bold" mb={-2}>
        {`We're sorry, this claim doesn't qualify for compensation`}
      </Text>
      <Button
        variant="secondary"
        size="xs"
        onClick={onOpen}
        mt={6}
        data-testid="view-status-details-button"
      >
        View Status Details
      </Button>
      <Drawer header="Claim history" isOpen={isOpen} onClose={onClose}>
        <Box w="full" ps={4} pe={6}>
          <VerticalStepTracker.Root>
            {claimHistory.map((stage) => (
              <VerticalStepTracker.Step
                key={stage.id}
                header={stage.subject}
                subheader={formatDate(stage.createdAt, 'dd/MM/yyyy HH:mm')}
                state="upcoming"
              >
                <VerticalStepTracker.StepContent>
                  <Box
                    fontSize="sm"
                    whiteSpace="pre-wrap"
                    dangerouslySetInnerHTML={{ __html: stage.textContent }}
                  />
                </VerticalStepTracker.StepContent>
              </VerticalStepTracker.Step>
            ))}
          </VerticalStepTracker.Root>
        </Box>
        <Box p={4} pb={6}>
          <Button onClick={onClose} w="full">
            Close
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

export function Summary({
  perPassengerCompensation,
  totalCompensation,
  eligibleForCompensation,
}: {
  perPassengerCompensation: string;
  totalCompensation: string;
  eligibleForCompensation: boolean;
}) {
  return (
    <Flex
      gap={0}
      h="100%"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Image
        src={eligibleForCompensation ? claimCompensationBg : noCompensationBg}
        w="256px"
        h="256px"
        display={{
          base: 'none',
          md: 'block',
        }}
        data-testid="compensation-background"
      />

      {eligibleForCompensation ? (
        <TotalCompensation
          perPassengerCompensation={perPassengerCompensation}
          totalCompensation={totalCompensation}
        />
      ) : (
        <DisabledCompensation />
      )}
    </Flex>
  );
}
