import { useClaimSuspense } from '@airhelp/webapp/react';
import { useParams } from 'react-router';
import { VStack, Flex, Text, useDisclosure, Link } from '@chakra-ui/react';
import { InfoBox } from '@airhelp/react';
import { Info } from '@airhelp/icons';
import { formatDate } from 'date-fns';
import * as DetailsBox from 'components/DetailsBox';
import { TravelDetailsDrawer } from 'components/TravelDetails';
import config from 'config';

export function Details() {
  const { id } = useParams();
  const {
    isOpen: isTravelDetailsDrawerOpen,
    onOpen: openTravelDetailsDrawer,
    onClose: closeTravelDetailsDrawer,
  } = useDisclosure();

  if (!id) {
    throw new Error('Id param is required for details component to work');
  }

  const { data: claim } = useClaimSuspense({
    params: { id: Number(id) },
  });

  const disruptedFlight = claim.itinerary.flights.find(
    (flight) => flight.disrupted,
  );

  const passengersData = claim.fellowPassengers;

  const mainPaxData = {
    name: claim.claimant.fullName,
    email: claim.claimant.email,
    phoneNumber: claim.claimant?.phoneNumber ?? null,
    address: claim.address.formattedAddress,
  };

  return (
    <VStack
      align="stretch"
      spacing={{
        base: 4,
        md: 6,
      }}
    >
      <DetailsBox.Root title="Itinerary">
        <DetailsBox.DataGroup>
          <DetailsBox.Row
            label="Airline"
            value={claim.disruptedFlightAirlineName}
          />

          <DetailsBox.Row
            label="Date"
            value={formatDate(
              disruptedFlight?.localDepartureDate as string,
              'd MMMM, yyyy',
            )}
          />

          <DetailsBox.Row label="Booking reference" value={claim.bookingRef} />
          <DetailsBox.Row
            label="Travel details"
            value="More"
            dataTestId="travel-details"
            onValueClick={openTravelDetailsDrawer}
          />
        </DetailsBox.DataGroup>
      </DetailsBox.Root>

      <DetailsBox.Root title="Disruption">
        <DetailsBox.DataGroup>
          <DetailsBox.Row label="Disruption type" value={claim.claimType} />
          <DetailsBox.Row
            label="Delay at final destination"
            value={claim.delay ?? '-'}
          />
          <DetailsBox.Row
            label="Extra expenses"
            value={claim.extraExpense ? 'Yes' : 'No'}
          />
        </DetailsBox.DataGroup>
      </DetailsBox.Root>
      <DetailsBox.Root title="Passengers">
        <DetailsBox.DataGroup
          title={mainPaxData.name}
          subtitle="Main passenger"
        >
          <DetailsBox.Row label="Email" value={mainPaxData.email} />
          <DetailsBox.Row
            label="Phone number"
            value={mainPaxData.phoneNumber ?? '-'}
          />
          <DetailsBox.Row label="Address" value={mainPaxData.address} />
        </DetailsBox.DataGroup>
        {passengersData.map((passenger) => (
          <DetailsBox.DataGroup
            key={passenger.id}
            title={passenger.fullName}
            subtitle="Additional Passenger"
          >
            <DetailsBox.Row
              label="Minor"
              value={passenger.minor ? 'Yes' : 'No'}
            />

            {passenger?.email ? (
              <DetailsBox.Row label="Email" value={passenger?.email} />
            ) : null}
          </DetailsBox.DataGroup>
        ))}
      </DetailsBox.Root>

      <InfoBox
        icon={<Info />}
        variant="primary"
        border="1px solid"
        borderColor="greyscale.400"
      >
        <Flex flexWrap="wrap" gap={1}>
          <Text color="greyscale.700" fontSize="md">
            Need to change the details of this claim?
          </Text>
          <Link isExternal href={config.contactUrl}>
            <Text color="primary.500" fontSize="md">
              Contact Customer Support.
            </Text>
          </Link>
        </Flex>
      </InfoBox>
      <TravelDetailsDrawer
        isOpen={isTravelDetailsDrawerOpen}
        onClose={closeTravelDetailsDrawer}
        flights={claim.itinerary.flights}
      />
    </VStack>
  );
}
