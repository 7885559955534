import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { AltArrowForward, AirplaneRight } from '@airhelp/icons';
import { Box, VStack, Circle, Flex } from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import { journeyUtils } from 'utils';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';
import { type Journey } from 'models/journey/Journey';
import AirportName from './AirportName';

const TripsList: React.FC<{ journeys: Journey[] }> = ({ journeys }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { trackCtaClick } = useTrackEvent();

  const filteredJourneys = journeys.filter((journey) => Boolean(journey.trip));

  const handleGoToDetails = (id: number) => {
    trackCtaClick('your trip', 'my account page');

    navigate(generatePath(PAGE_PATHS.JOURNEY_PAGE, { id }));
  };

  const styleActive = {
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'primary.500',
    boxShadow: '0px 0px 0px 4px #DFEDFF',
    '[data-icon="trip-icon-wrapper"]': {
      bgColor: 'primary.500',
      '[data-icon="trip-icon"]': {
        color: 'greyscale.100',
      },
    },
  };

  return (
    <VStack
      p={{ base: 0, md: 8 }}
      w={{ base: '100%', md: '67%' }}
      spacing={4}
      borderWidth={{ base: 0, md: '1px' }}
      borderColor="greyscale.400"
      borderStyle="solid"
      borderRadius="lg"
      alignItems="flex-start"
    >
      {filteredJourneys.map((item, index) => {
        const flights = item.itineraries[0].flights;
        const firstFlight = flights[0];
        const lastFlightIndex = flights.length - 1;
        const lastFlight = flights[lastFlightIndex];
        const brandName = item.trip?.brandName;

        const departureAirport = firstFlight.departureAirport;
        const arrivalAirport = lastFlight.arrivalAirport;

        const departureAirportCity =
          journeyUtils.useCityTranslations(departureAirport) ||
          departureAirport?.city;

        const arrivalAirportCity =
          journeyUtils.useCityTranslations(arrivalAirport) ||
          arrivalAirport?.city;

        const departureAirportIataCode = departureAirport
          ? departureAirport.iata
          : '';
        const arrivalAirportIataCode = arrivalAirport
          ? arrivalAirport.iata
          : '';
        const hasBrandName = !!brandName && brandName !== '';

        return (
          <Flex
            w="100%"
            p={4}
            textColor="primary.700"
            justifyContent="space-between"
            borderRadius="lg"
            borderColor="greyscale.400"
            borderStyle="solid"
            borderWidth="1px"
            flexDirection={{ base: 'column', xl: 'row' }}
            onClick={() => {
              handleGoToDetails(item.id);
            }}
            tabIndex={20 + index}
            _hover={styleActive}
            _focus={styleActive}
            _focusVisible={{ outline: 'none' }}
            key={`trip-info-${item.id}`}
            data-testid={`trip-info-${item.id}`}
          >
            <Flex
              width="100%"
              alignItems="center"
              justifyContent={{ base: 'space-between', xl: 'unset' }}
              color="primary.900"
            >
              <AirportName
                airportCity={departureAirportCity || ''}
                airportCode={departureAirportIataCode}
                side="left"
              />
              <AirplaneRight
                color="greyscale.600"
                w={5}
                h="auto"
                mx={4}
                transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
              />
              <AirportName
                airportCity={arrivalAirportCity || ''}
                airportCode={arrivalAirportIataCode}
                side="right"
              />
            </Flex>
            <Flex
              width="100%"
              mt={{ base: 6, md: 2, xl: 0 }}
              alignItems="center"
              justifyContent="end"
            >
              {hasBrandName ? (
                <Box
                  me={{ base: 2, md: '2vw' }}
                  fontSize="sm"
                  width={{ base: '100%', xl: 'unset' }}
                  color="greyscale.600"
                >
                  {t('common.booked_via', { brandName })}
                </Box>
              ) : null}
              <Circle
                size={8}
                bgColor="primary.100"
                justifySelf="flex-end"
                data-icon="trip-icon-wrapper"
              >
                <AltArrowForward
                  color="primary.500"
                  data-icon="trip-icon"
                  transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
                />
              </Circle>
            </Flex>
          </Flex>
        );
      })}
    </VStack>
  );
};

export default TripsList;
