import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { AuthFormOAuth, AuthSignupForm } from '@airhelp/funnel';
import { Link, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSignUp } from '@airhelp/session';
import { SSO_PROVIDER, oAuthLink } from 'api/http/http';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import {
  useMarketingConsentLabel,
  usePrivacyConsentLabel,
} from 'elements/UserConsentsForm/common';
import {
  prefetchMyProfile,
  useMyProfile,
} from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type SignupSubmitData } from 'models/userProfile/Signup';

const SignupView = ({ email = '' }) => {
  const queryClient = useQueryClient();
  const { setLoginData } = useContext(TrackingContext);
  const { clearProfile } = useMyProfile();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>();
  const tracking = useTracking();
  const { trackCtaClick } = useTrackEvent();

  const { t } = useTranslation();

  const { mutate: authSignUp, isPending } = useSignUp({
    onError: (error) => {
      const errorMessage: string | null | undefined = handleErrorMessage(error);
      setApiError(errorMessage);
    },
    onSuccess: async () => {
      clearProfile();

      const userProfile = await prefetchMyProfile(queryClient);

      if (userProfile) {
        setLoginData({ method: 'email', user: userProfile });
      }

      tracking.trackEvent({ name: 'accountCreated' });

      navigate({ pathname: generatePath(PAGE_PATHS.HOME_PAGE) });
    },
  });

  const handleErrorMessage = (errorResponse) => {
    const errorBody = errorResponse?.response;
    let errorMessage;

    if (errorResponse?.response?.status === 409) {
      errorMessage = t('common.account_already_exist');
    } else if (errorBody?.errors) {
      const errors = errorBody.errors;
      const arrayMessages = Object.keys(errors as Record<string, string>).map(
        (key) => {
          return `${key} ${errors[key]}`;
        },
      );

      errorMessage =
        arrayMessages.join(', ') || t('errors.something_went_wrong');
    } else {
      errorMessage = errorBody?.error || t('errors.something_went_wrong');
    }

    return errorMessage;
  };

  const onSubmit = (data: SignupSubmitData) => {
    const {
      email: emailData,
      password,
      privacyConsent,
      marketingConsent,
    } = data;

    authSignUp({
      user: { email: emailData, password, privacyConsent, marketingConsent },
    });
  };

  return (
    <AuthSignupForm
      formError={apiError ? apiError : undefined}
      i18n={{
        title: t('authentication.create_an_account'),
        actionText: t('authentication.create_an_account'),
        confirmPasswordPlaceholder: t('authentication.password_confirmation'),
        divider: t('authentication.create_an_account_via'),
        emailPlaceholder: t('common.email'),
        passwordPlaceholder: t('authentication.password'),
        marketingConsentLabel: useMarketingConsentLabel(),
        privacyConsentLabel: usePrivacyConsentLabel(),
        selectAllLabel: t('common.select_all'),
        signinCallout: (
          <>
            <Text
              as="span"
              fontSize="sm"
              color="greyscale.600"
              display="inline-block"
            >
              {t('authentication.already_member')}
            </Text>
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.LOGIN_PAGE)}
              onClick={() => {
                trackCtaClick('login', 'login page');
              }}
              ps={1}
              fontSize="sm"
              fontWeight="normal"
              color="primary.500"
              _visited={{}}
              data-testid="already_member"
            >
              {t('authentication.login_form.log_in_here')}
            </Link>
          </>
        ),
      }}
      oAuth={
        <AuthFormOAuth
          facebookLink={oAuthLink(SSO_PROVIDER.FACEBOOK)}
          onFacebookClick={() => {
            trackCtaClick('facebook sso', 'register via facebook sso');
          }}
          googleLink={oAuthLink(SSO_PROVIDER.GOOGLE)}
          onGoogleClick={() => {
            trackCtaClick('google sso', 'register via google sso');
          }}
          microsoftLink={oAuthLink(SSO_PROVIDER.MICROSOFT)}
          onMicrosoftClick={() => {
            trackCtaClick('microsoft sso', 'register via microsoft sso');
          }}
          appleLink={oAuthLink(SSO_PROVIDER.APPLE)}
          onAppleClick={() => {
            trackCtaClick('apple sso', 'login via apple sso');
          }}
        />
      }
      onSubmit={(data) => {
        trackCtaClick('signup', 'signup page');
        onSubmit(data);
      }}
      defaultValues={{ email }}
      isSubmitting={isPending}
    />
  );
};

export default SignupView;
