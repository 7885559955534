import {
  useClaimSuspense,
  useClaimHistorySuspense,
} from '@airhelp/webapp/react';
import { useMemo } from 'react';
import {
  CLAIM_COMPENSATION_COLLECTABLE_STATES,
  CLAIM_FINAL_STATES,
  CLAIM_LEGAL_STATES,
  CLAIM_SUCCESSFUL_STATES,
} from './claim-states';

export function useClaimState(id: number | string) {
  const { data: claim } = useClaimSuspense({
    params: {
      id: Number(id),
    },
  });
  const { data: history } = useClaimHistorySuspense({
    params: { id: Number(id) },
  });

  return useMemo(() => {
    const state = history.at(0)?.state;

    if (!state || !history) {
      return {
        state: 'unknown',
        isCompensationCollectible: false,
        isClosed: false,
        isSuccessful: false,
        isAtLegalStage: false,
        history,
        track: claim.track,
      };
    }

    const isCompensationCollectible = (
      CLAIM_COMPENSATION_COLLECTABLE_STATES as string[]
    ).includes(state);

    const isClosed = (CLAIM_FINAL_STATES as string[]).includes(state);
    const isSuccessful =
      (CLAIM_SUCCESSFUL_STATES as string[]).includes(state) ||
      (!claim.reprocessed && claim.cloned);
    const isAtLegalStage = (CLAIM_LEGAL_STATES as string[]).includes(state);

    return {
      state,
      isCompensationCollectible,
      isClosed,
      isSuccessful,
      isAtLegalStage,
      history,
      track: claim.track,
    };
  }, [history, claim]);
}
