import { Flex, Heading, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { cloneElement, type PropsWithChildren, type ReactElement } from 'react';
import { AltArrowForward } from '@airhelp/icons';
import { NavLink } from 'react-router-dom';

export function SupportBoxItem({
  icon,
  title,
  description,
  link,
  onClick,
  dataTestId,
}: PropsWithChildren<{
  icon: ReactElement;
  title: string;
  description: string;
  link?: string;
  dataTestId?: string;
  onClick?: () => void;
}>) {
  const styledIcon = cloneElement(icon, {
    color: 'primary.400',
    width: '20px',
    height: '20px',
  });

  const isLinkExternal = link?.startsWith('http');
  const ItemLink = onClick
    ? ({ children }: PropsWithChildren) => children
    : ({ children }: PropsWithChildren) => (
        <Link
          as={isLinkExternal ? undefined : NavLink}
          href={link}
          isExternal={isLinkExternal}
          display="inline-block"
          w="full"
        >
          {children}
        </Link>
      );

  return (
    <ItemLink>
      <HStack
        py={4}
        px={{
          base: 0,
          lg: 2,
        }}
        _hover={{ bg: 'greyscale.200' }}
        borderRadius="md"
        onClick={onClick}
        w="full"
        cursor={onClick ? 'pointer' : undefined}
        data-testid={dataTestId}
      >
        <Flex
          justify="center"
          align="center"
          w="40px"
          h="40px"
          rounded="full"
          borderWidth="2px"
          borderStyle="solid"
          borderColor="greyscale.300"
          me={2.5}
        >
          {styledIcon}
        </Flex>
        <VStack flex={1} gap={0} align="start">
          <Heading fontSize="sm" as="h4">
            {title}
          </Heading>
          <Text fontSize="xs" color="greyscale.600">
            {description}
          </Text>
        </VStack>
        <Flex p={2} justify="center" align="center">
          <AltArrowForward
            width={6}
            height={6}
            color="greyscale.500"
            data-testid="arrow-forward-icon"
          />
        </Flex>
      </HStack>
    </ItemLink>
  );
}
