import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, HStack, Heading } from '@chakra-ui/react';
import faqFormal from 'assets/images/faq-formal@2x.png';
import tripsRoute from 'assets/images/trips-route@2x.png';
import flightsChecked from 'assets/images/flights-checked-formal@2.png';
import supportFormal from 'assets/images/support-formal@2x.png';
import { PAGE_PATHS } from 'config/routes';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useZowie } from 'contexts/ZowieContext';
import ActionCard from 'elements/ActionCard';
import { type UserProfile } from 'models/userProfile/UserProfile';
import { LinkTarget } from 'types/utils';
import { useTrackEvent } from 'utils/tracking/hooks';
import {
  getWebappNewClaimUrl,
  getWebsiteAhPlusPageUrl,
  getWebsitePageUrl,
} from 'utils/sites';

interface IComponent {
  userProfile: UserProfile;
  canAddJourney: boolean;
}

const HowCanWeHelp: React.FC<IComponent> = ({ userProfile, canAddJourney }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();
  const { openZowieChat } = useZowie();
  const { locale } = useLocale();

  const ahplusCoverageActive = userProfile.policy.coverage?.active;
  const showProtectFutureTrip =
    userProfile.policy.dashboard?.upsellEnabled || canAddJourney;
  const orderProtectTripCard = { order: ahplusCoverageActive ? 1 : 2 };
  const orderCompensationCard = { order: ahplusCoverageActive ? 2 : 1 };

  const onFaqClick = () => {
    trackCtaClick('see faq', 'home page');
  };

  const onSupportClick = () => {
    trackCtaClick('need help contact support', 'home page');

    openZowieChat();
  };

  const onClaimClick = () => {
    trackCtaClick('add claim', 'home page');
  };

  const onAddJourneyClick = () => {
    trackCtaClick('add trip button', 'home page');

    if (canAddJourney) {
      navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
        state: {
          from: 'dashboard',
        },
      });
    }
  };

  const actionCardAltStyling = {
    p: 4,
    pl: { base: 4, xl: 2 },
    minWidth: { base: '180px', lg: 'unset' },
    columnGap: 2,
    sx: {
      '.header': { fontSize: 'md', fontWeight: 'medium' },
      base: { img: { sm: { w: '32px', h: 'auto' } } },
      '.content': {
        textWrap: 'wrap',
      },
      '.content-wrapper': {
        ml: 0,
        alignItems: { base: 'flex-start', xlg: 'center' },
        flexDirection: { base: 'column', xlg: 'row' },
      },
      '.icon': {
        width: { base: '48px', lg: '64px' },
        height: { base: '48px', lg: '64px' },
        mb: 3,
      },
      '.button': {
        alignSelf: 'flex-start',
        display: { base: 'none', xl: 'inherit' },
      },
    },
    flexDirection: { base: 'column', xl: 'row' },
    boxShadow: {
      base: '0px 12px 16px -4px rgba(30, 41, 54, 0.08), 0px 4px 8px -4px rgba(30, 41, 54, 0.04);',
      md: 'unset',
    },
  };

  return (
    <Box mb={{ base: 8, md: 12 }} data-testid="how-can-we-help-shortcuts">
      <Heading as="h5" variant="h5" mb={4}>
        {t('dashboard.how_can_we_help.title')}
      </Heading>
      <HStack
        width={{ base: 'calc(100% + 32px)', lg: '100%' }}
        spacing={4}
        alignItems="stretch"
        overflowX={{ base: 'auto', lg: 'unset' }}
        px={{ base: 4, lg: 0 }}
        ms={{ base: -4, lg: 0 }}
        pb={{ base: 6, md: 0 }}
        sx={{
          scrollbarWidth: 'none',
          msOverflowStyle: '-ms-autohiding-scrollbar',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <ActionCard
          header={t('dashboard.how_can_we_help.add_claim')}
          handleClick={onClaimClick}
          externalLink={getWebappNewClaimUrl()}
          externalLinkTarget={LinkTarget.SELF}
          imageSrc={flightsChecked}
          data-testid="shortcut-claim-compensation"
          {...actionCardAltStyling}
          {...orderCompensationCard}
        />
        {showProtectFutureTrip ? (
          <ActionCard
            header={t('dashboard.how_can_we_help.protect_my_future_trip')}
            handleClick={onAddJourneyClick}
            externalLink={
              !canAddJourney
                ? getWebsiteAhPlusPageUrl('pricing-plans', locale)
                : undefined
            }
            externalLinkTarget={LinkTarget.SELF}
            imageSrc={tripsRoute}
            data-testid="shortcut-add-journey"
            {...actionCardAltStyling}
            {...orderProtectTripCard}
          />
        ) : null}
        <ActionCard
          header={t('dashboard.how_can_we_help.check_faqs')}
          handleClick={onFaqClick}
          imageSrc={faqFormal}
          externalLink={getWebsitePageUrl('faqPath', locale)}
          data-testid="shortcut-faq"
          {...{ order: 3 }}
          {...actionCardAltStyling}
        />
        <ActionCard
          header={t('dashboard.how_can_we_help.chat_with_us')}
          handleClick={onSupportClick}
          imageSrc={supportFormal}
          data-testid="shortcut-support"
          {...{ order: 4 }}
          {...actionCardAltStyling}
        />
      </HStack>
    </Box>
  );
};

export default HowCanWeHelp;
