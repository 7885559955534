import { useClaimSuspense } from '@airhelp/webapp/react';
import { useTranslation } from 'react-i18next';

export function useCompensationData({ claimId }: { claimId: number }) {
  const { i18n } = useTranslation();

  const { data: claimData, isLoading } = useClaimSuspense({
    params: { id: claimId },
  });

  const payoutValues = claimData?.claimPayout;

  const totalPassengers = claimData?.fellowPassengers.length + 1; // include the claimant

  const claimCompensation = Number(
    payoutValues?.finalCompensationInPreferredCurrency,
  ); // base compensation that we got from airline
  const legalFee = Number(
    payoutValues?.legalActionFeeAmountInPreferredCurrency,
  );
  const serviceFee = Number(payoutValues?.serviceFeeAmountInPreferredCurrency);
  const finalCompensation = Number(
    payoutValues?.payoutValueInPreferredCurrency,
  ); // final payout value, already have the legal fee and service fee deducted

  const currency = payoutValues?.currency;

  const finalCompensationPerPassenger = finalCompensation / totalPassengers;

  const formatPayoutValues = (value: number) =>
    Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 3,
      signDisplay: 'negative',
    }).format(value);

  const formattedValues = {
    legalFee: formatPayoutValues(legalFee),
    serviceFee: formatPayoutValues(serviceFee),
    claimCompensation: formatPayoutValues(claimCompensation),
    finalCompensation: formatPayoutValues(finalCompensation),
    finalCompensationPerPassenger: formatPayoutValues(
      finalCompensationPerPassenger,
    ),
  };

  return {
    currency: payoutValues?.currency,
    freeOfCharge: Boolean(payoutValues?.freeOfCharge),
    formatPayoutValues,
    formattedValues,
    legalFee,
    serviceFee,
    claimCompensation,
    finalCompensation,
    finalCompensationPerPassenger,
    isLoading,
  };
}
