import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import {
  Center,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Text,
  Button,
  Flex,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import happyBuka from 'assets/images/air-bundle/happy-buka.png';
import clearLocationHistory from 'utils/clearLocationHistory';
import { type AirBundleSubscription } from 'models/userProfile/AirBundleSubscription';
import { type Journey } from 'models/journey/Journey';

interface IComponent {
  journey: Journey;
  airBundleSubscriptionData: AirBundleSubscription;
}

const key = 'air_payout.modal.success.credits_left';

const AirBundleAppliedModal: React.FC<IComponent> = ({
  journey,
  airBundleSubscriptionData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    clearLocationHistory(navigate);
    onClose();
  };

  const { hasAirLuggage, hasAirPayout } = journey;

  const {
    airPayoutCreditsLeft,
    airLuggageCreditsLeft,
    airPayoutUnlimitedAccess,
    airLuggageUnlimitedAccess,
    hasCredit,
  } = airBundleSubscriptionData;

  const getSubtitle = () => {
    if (hasAirPayout && !hasAirLuggage) {
      return t('air_payout.modal.success.protected_air_payout_only');
    }
    if (hasAirLuggage && !hasAirPayout) {
      return t('air_payout.modal.success.protected_air_luggage_only');
    }
    return t('air_payout.modal.success.protected_air_payout_and_air_luggage');
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW="400px" backgroundColor="greyscale.100">
        <Center
          h="320px"
          background="greyscale.100"
          w="100%"
          position="relative"
          borderTopRadius="md"
        >
          <ModalCloseButton
            borderRadius="sm"
            boxSize={2}
            top={6}
            right={6}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2.5 },
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            position="absolute"
            data-testid="modal-close-button"
          />
          <Image src={happyBuka} bottom={9} position="absolute" />
        </Center>
        <VStack
          alignItems="center"
          mt={4}
          mb={6}
          mx={4}
          backgroundColor="greyscale.100"
          spacing={6}
        >
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="700" fontSize="3xl">
              {t('common.great_news')}
            </Text>
            <Text
              fontWeight="500"
              fontSize="lg"
              textAlign="center"
              data-testid="subtitle-details"
            >
              {getSubtitle()}
            </Text>
          </Flex>
          <Text color="greyscale.700" fontSize="md" textAlign="center">
            {t('air_payout.modal.success.not_charged')}
          </Text>
          {hasCredit ? (
            <InfoBox
              backgroundColor="greyscale.300"
              w="100%"
              p={5}
              data-testid="info-box-details"
            >
              {Boolean(airPayoutCreditsLeft) && (
                <HStack justifyContent="space-between" mb={3}>
                  <Text color="greyscale.700" fontSize="md">
                    {t('air_payout.name')}
                  </Text>
                  <Text fontSize="md" fontWeight="500">
                    {t(key, {
                      count: airPayoutCreditsLeft,
                    })}
                  </Text>
                </HStack>
              )}
              {Boolean(airLuggageCreditsLeft) && (
                <HStack justifyContent="space-between">
                  <Text color="greyscale.700" fontSize="md">
                    {t('air_luggage.name')}
                  </Text>
                  <Text fontSize="md" fontWeight="500">
                    {t(key, {
                      count: airLuggageCreditsLeft,
                    })}
                  </Text>
                </HStack>
              )}
            </InfoBox>
          ) : null}
          {airLuggageUnlimitedAccess ||
          airPayoutUnlimitedAccess ||
          hasCredit ? (
            <InfoBox
              backgroundColor="greyscale.300"
              isChat
              icon={<Info color="primary.500" />}
            >
              {t('air_payout.modal.success.dont_forget')}
            </InfoBox>
          ) : null}
          <Button
            onClick={handleClose}
            w="100%"
            variant="primary"
            size="xs"
            fontSize="sm"
            data-testid="action-button"
          >
            {t('common.check_trip_details')}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default AirBundleAppliedModal;
