import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router-dom';
import { PAGE_PATHS } from 'config/routes';
import { ESSENTIAL_TO_SMART_UPGRADE } from 'config/channels';

interface UpgradeModalNavigationProps {
  onReadMore: () => void;
  onUpgradeNow: () => void;
}

export const UpgradeModalNavigation: React.FC<UpgradeModalNavigationProps> = ({
  onReadMore,
  onUpgradeNow,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          base: 'column',
          md: 'row',
        },
        gap: '8px',
        columnGap: '8px',
        padding: '0 16px 16px 16px',
        alignItems: 'stretch',
      }}
    >
      <Button
        onClick={onReadMore}
        as={ReactLink}
        to={{
          pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE),
        }}
        variant="outline"
        size="md"
        sx={{
          borderColor: 'primary.500',
          borderWidth: '2px',
          fontWeight: 'bold',
          color: 'primary.500',
          fontSize: '14px',
          width: {
            base: '100%',
            md: '50%',
          },
        }}
        data-testid="upgrade-modal-read-more-btn"
      >
        {t('new_benefits_modal.read_more')}
      </Button>
      <Button
        as={ReactLink}
        to={{
          pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
          search: `?channel=${ESSENTIAL_TO_SMART_UPGRADE}&upgrade=true`,
        }}
        onClick={onUpgradeNow}
        size="md"
        sx={{
          fontWeight: 'bold',
          width: {
            base: '100%',
            md: '50%',
          },
          fontSize: '14px',
        }}
        data-testid="upgrade-modal-upgrade-now-btn"
      >
        {t('new_benefits_modal.upgrade_now')}
      </Button>
    </Box>
  );
};
