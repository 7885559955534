import { Heading, VStack } from '@chakra-ui/react';
import { Chat, Envelope, Help } from '@airhelp/icons';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import config from 'config';
import SupportBoxItem from 'elements/SupportBoxItem';
import { useZowie } from '../../contexts/ZowieContext';

export function SupportBox() {
  const { openZowieChat } = useZowie();
  const { locale } = useLocale();

  return (
    <VStack
      gap={4}
      p={{
        base: 4,
        md: 6,
      }}
      borderRadius="xl"
      bg="white"
      align="start"
    >
      <Heading
        display={{
          base: 'none',
          xl: 'flex',
        }}
        fontSize="xl"
        as="h3"
      >
        How can we help you?
      </Heading>

      <VStack gap={0} w="full">
        {/*TODO: Translations*/}
        <SupportBoxItem
          icon={<Chat />}
          title="Chat"
          description={`Our team's here for you`}
          onClick={openZowieChat}
          dataTestId="support-box-chat"
        />
        <SupportBoxItem
          icon={<Envelope />}
          title="Customer Support"
          description="Contact Our Support Team!"
          dataTestId="support-box-customer-support"
          link={config.contactUrl}
        />
        <SupportBoxItem
          icon={<Help />}
          title={`FAQ's`}
          dataTestId="support-box-faq"
          description="We answer your questions"
          link={config.helpUrl.replace('{{locale}}', locale)}
        />
      </VStack>
    </VStack>
  );
}
