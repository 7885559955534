import { Flex, Skeleton, Box } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useCompensationData } from './useCompensationData';
import { Table } from './parts/Table';
import { Summary } from './parts/Summary';

export function Compensation() {
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for compensation component to work');
  }

  const { isLoading, formattedValues, claimCompensation } = useCompensationData(
    {
      claimId: Number(id),
    },
  );

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        gap={{
          base: 2,
          md: 4,
        }}
        backgroundColor={{
          base: 'transparent',
          md: 'greyscale.100',
        }}
        rounded="lg"
        px={{
          base: 0,
          md: 4,
        }}
        py={{
          base: 0,
          md: 8,
        }}
      >
        <Box
          backgroundColor="greyscale.100"
          rounded="lg"
          w="100%"
          px={{
            base: 4,
            md: 0,
          }}
          py={{
            base: 8,
            md: 0,
          }}
        >
          <Summary
            eligibleForCompensation={claimCompensation > 0}
            totalCompensation={formattedValues.finalCompensation}
            perPassengerCompensation={
              formattedValues.finalCompensationPerPassenger
            }
          />
        </Box>
        <Table />
      </Flex>
    </Skeleton>
  );
}
