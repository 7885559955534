import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { GOODWILL_FUNNEL_CHANNEL } from 'config/channels';
import { PAGE_PATHS } from 'config/routes';
import {
  NO_FEE_UP_TO_COMPENSATION_AMOUNT,
  HOURS_APPLY_THRESHOLD,
} from 'config/benefits';
import ContactLink from 'elements/ContactLink/ContactLink';
import { type Offer } from 'models/Offers';
import type { UserProfile } from 'models/userProfile/UserProfile';
import chakraStyles from 'utils/styles/chakra';
import { getWebappClaimUrl, getWebappUrl } from 'utils/sites';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type BenefitSection } from 'components/Membership/CheckoutView/PlanDetails/types';
import BenefitsSection from './BenefitsSection';

const Side: React.FC<{
  channel?: string;
  claimId?: string;
  claimEnquiryUuid?: string;
  isUpgradeOffer?: boolean;
  productDetails?: Offer;
  userProfile: UserProfile;
}> = ({
  channel,
  claimId,
  claimEnquiryUuid,
  isUpgradeOffer,
  userProfile,
  productDetails,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reloadProfile } = useMyProfile();

  const dashboardVisited = userProfile?.policy.dashboard?.dashboardVisited;

  const isUserArrivedFromGoodwill =
    claimEnquiryUuid && channel === GOODWILL_FUNNEL_CHANNEL;
  const isUserArrivedFromClaimSubmissionFunnel = Boolean(claimId);

  const planName = productDetails?.displayName;
  const airPayoutPerk = productDetails?.airPayoutPerk;
  const airPayoutPayment = airPayoutPerk?.config.payout;
  const airPayoutCredits = airPayoutPerk?.credits;

  const airLuggagePerk = productDetails?.airLuggagePerk;
  const airLuggagePayout = airLuggagePerk?.config.payout;

  const goToDashboard = async () => {
    await reloadProfile();

    navigate(generatePath(PAGE_PATHS.HOME_PAGE));
  };

  const getHeader = () => {
    switch (true) {
      case isUpgradeOffer:
        return t('upgrade_confirmation.upgrade_successful', {
          planName,
        });
      case dashboardVisited:
        return t('payment.succeeded.membership_title');
      default:
        return t('common.airhelp_member');
    }
  };

  const benefitsSectionList: BenefitSection[] =
    airPayoutCredits && airPayoutPayment && airLuggagePayout
      ? [
          {
            text: t('upgrade_confirmation.protection_for_trips', {
              count: airPayoutCredits,
            }),
            key: 'protection',
            benefits: [
              {
                text: t('upgrade_confirmation.flight_disrupted', {
                  amount: airPayoutPayment,
                }),
              },
              {
                text: t('upgrade_confirmation.bag_lost', {
                  amount: airLuggagePayout,
                }),
              },
              {
                text: t('upgrade_confirmation.lounge_access'),
              },
            ],
          },
          {
            text: t('upgrade_confirmation.annual_support'),
            key: 'annual-support',
            benefits: [
              {
                text: t('upgrade_confirmation.no_fees_up_to', {
                  amount: NO_FEE_UP_TO_COMPENSATION_AMOUNT,
                }),
              },
              {
                text: t('upgrade_confirmation.dedicated_support'),
              },
              {
                text: t('upgrade_confirmation.flight_status_updates'),
              },
            ],
          },
          {
            text: t('upgrade_confirmation.travel_perks'),
            key: 'travel-perks',
            benefits: [
              {
                text: t('upgrade_confirmation.discounts'),
              },
            ],
          },
        ]
      : [];

  const whenBenefitsApplySectionList: BenefitSection[] = [
    {
      text: t('upgrade_confirmation.when_apply'),
      key: 'when-apply',
      benefits: [
        {
          text: t('upgrade_confirmation.new_benefits_apply_to', {
            count: HOURS_APPLY_THRESHOLD,
          }),
        },
        {
          text: t('upgrade_confirmation.if_flights_depart', {
            count: HOURS_APPLY_THRESHOLD,
            planName,
          }),
        },
      ],
    },
  ];

  const getButton = () => {
    switch (true) {
      case isUserArrivedFromGoodwill:
        return (
          <Button
            as={Link}
            href={`${getWebappUrl()}/claim_enquiries/${claimEnquiryUuid}`}
            size="m"
            width={{ base: '100%', lg: 'initial' }}
            data-testid="goto-claim-funnel"
            {...chakraStyles.buttonLinkStyles}
          >
            {t('common.continue_with_my_claim')}
          </Button>
        );
      case isUserArrivedFromClaimSubmissionFunnel:
        return (
          <Button
            as={Link}
            href={`${getWebappClaimUrl()}${claimId}`}
            size="m"
            width={{ base: '100%', lg: 'initial' }}
            isExternal
            data-testid="goto-claim-details"
            {...chakraStyles.buttonLinkStyles}
          >
            {t('common.continue')}
          </Button>
        );
      case isUpgradeOffer:
        return (
          <Button
            size="m"
            onClick={goToDashboard}
            width={{ base: '100%', lg: 'initial' }}
            data-testid="goto-ahplus-dashboard"
          >
            {t('upgrade_confirmation.go_to_dashboard')}
          </Button>
        );
      default:
        return (
          <Button
            size="m"
            onClick={goToDashboard}
            width={{ base: '100%', lg: 'initial' }}
            data-testid="goto-ahplus-dashboard"
          >
            {t('common.get_started')}
          </Button>
        );
    }
  };

  return (
    <Box>
      <Heading variant="h1">{getHeader()}</Heading>
      {isUpgradeOffer && benefitsSectionList ? (
        <Box mt={10} data-testid="benefits_upgrade_plan">
          <Heading
            variant="h5"
            mt={8}
            mb={3}
            fontWeight="500"
            fontSize="lg"
            data-testid="benefits_title"
          >
            {t('upgrade_confirmation.your_new_plan', { planName })}
          </Heading>
          {benefitsSectionList.map((section: BenefitSection) => (
            <BenefitsSection
              name={section.text}
              benefits={section.benefits}
              key={section.key}
              data-testid={section.key}
            />
          ))}
          <Box mt={8}>
            {whenBenefitsApplySectionList.map((section: BenefitSection) => (
              <BenefitsSection
                name={section.text}
                benefits={section.benefits}
                key={section.key}
                data-testid={section.key}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <>
          <Text fontSize="lg" fontWeight="medium" mt={3}>
            <ContactLink
              tKey="payment.succeeded.you_will_receive_confirmation_email"
              fontSize="lg"
              fontWeight="medium"
            />
          </Text>
          <Heading variant="h4" mt={10} mb={2}>
            {t('common.get_started')}
          </Heading>
          <Text fontSize="lg">
            {t('payment.succeeded.add_your_upcoming_flights')}
          </Text>
        </>
      )}
      <Flex mt={8}>{getButton()}</Flex>
    </Box>
  );
};

export default Side;
