import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Check } from '@airhelp/icons';
import {
  HStack,
  Spacer,
  Text,
  VStack,
  type StackProps,
  Heading,
} from '@chakra-ui/react';
import { TripTier } from '@airhelp/plus';
import JourneyActions from 'components/Journey/JourneyActions';
import { PAGE_PATHS } from 'config/routes';
import { journeyUtils } from 'utils';
import { type Journey } from 'models/journey/Journey';
import { TILE_STYLE } from '../config';
import TileFooter from './TileFooter';

interface IComponent extends StackProps {
  journey: Journey;
}

const JourneyTile: React.FC<IComponent> = ({ journey, ...props }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const flightsCount = journey.itineraries.reduce(
    (noOfFlights, itinerary) => noOfFlights + itinerary.flights.length,
    0,
  );

  const isReturnTrip = journey.itineraries.length === 2;

  const firstFlightDepartureDate = journeyUtils.useFlightDateFormatted(
    journey.itineraries[0].flights[0].localDepartureDate,
  );

  const journeyName = journey.generateDisplayName(t, i18n.language);
  const isAirBundle = journey.hasAirLuggage || journey.hasAirPayout;

  const handleCardClick = ({ event }) => {
    event.stopPropagation();

    if (event.target.nodeName !== 'BUTTON') {
      navigate(
        generatePath(PAGE_PATHS.JOURNEY_PAGE, { id: journey.id.toString() }),
      );
    }
  };

  return (
    <VStack
      width="100%"
      onClick={(event) => {
        handleCardClick({ event });
      }}
      {...TILE_STYLE}
      {...props}
      _hover={{
        cursor: 'pointer',
        borderColor: 'primary.500',
        boxShadow: '0px 0px 0px 4px #DFEDFF',
      }}
      data-testid={`tile-active-${journey.id}`}
    >
      <HStack justifyContent="space-between" color="initial" width="100%">
        <Heading
          variant="h5"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          title={journeyName}
          _groupHover={{ color: 'primary.500' }}
        >
          {journeyName}
        </Heading>
        <JourneyActions journey={journey} />
      </HStack>
      <Text align="start" color="greyscale.500" fontSize="sm" width="100%">
        {t('common.on', { date: firstFlightDepartureDate })}
      </Text>
      <Spacer />
      <HStack justifyContent="space-between" width="100%">
        <VStack>
          {journey?.trip?.tier ? (
            <HStack data-testid="benefits-b2b">
              <Check color="success.500" />
              <Text
                fontSize="xs"
                color="greyscale.700"
                fontWeight="medium"
                ms="4px !important"
              >
                {journey.trip.tier === TripTier.ESSENTIAL
                  ? `${t('dashboard.flying_with_ahp')} ${t('pricing.plans.essential')}`
                  : `${t('dashboard.flying_with_ahp')} ${t('pricing.plans.complete')}`}
              </Text>
            </HStack>
          ) : null}
        </VStack>
        {isAirBundle ? (
          <VStack>
            <HStack data-testid="benefits-b2b">
              <Check color="success.500" />
              <Text
                fontSize="xs"
                color="greyscale.700"
                fontWeight="medium"
                ms="4px !important"
              >
                {t('common.insurance')}
              </Text>
            </HStack>
          </VStack>
        ) : null}
        <Spacer />
        <TileFooter isReturnTrip={isReturnTrip} flightsCount={flightsCount} />
      </HStack>
    </VStack>
  );
};

export default JourneyTile;
