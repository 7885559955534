import React, { useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import {
  getOfferByTier,
  useGetProductTitle,
} from 'components/Membership/membershipHelper';
import { UNKNOWN_CHANNEL } from 'config/channels';
import { TrackingContext } from 'contexts';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useStripeOrder from 'hooks/api/payments/useStripeOrder';
import { type Offer } from 'models/Offers';
import { useTrackCheckout } from 'utils/tracking/hooks';
import { EcommerceEvent } from 'utils/tracking/props/ga4/types';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';
import SucceedSummaryView from './SucceedSummaryView';

interface IComponent {
  offers: Record<string, Offer>;
  renewOffer?: Offer;
}

const Succeeded: React.FC<IComponent> = ({ offers, renewOffer }) => {
  const [searchParams] = useSearchParams();
  const { checkoutDataDispatch, checkoutData } = useContext(TrackingContext);
  const { trackCheckout } = useTrackCheckout();

  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useFetchMyProfile();

  const tier = searchParams.get('tier') as string | undefined;

  const upgrade = searchParams.get('upgrade') as string | undefined;
  const channel = searchParams.get('channel') || UNKNOWN_CHANNEL;
  const claimId = searchParams.get('claimId') as string | undefined;

  const claimEnquiryUuid = searchParams.get('claimEnquiryUuid') as
    | string
    | undefined;
  const paymentIntentId = searchParams.get('payment_intent') as string;

  const isUpgradeOffer = Boolean(upgrade);

  const {
    data: order,
    isLoading: isLoadingOrder,
    isError,
  } = useStripeOrder({ paymentIntentId });

  const productDetails = renewOffer || getOfferByTier(offers, tier);

  const productTitle = useGetProductTitle({
    productName: productDetails?.name,
  });

  // track successful membership purchase
  const trackedPurchaseGA4Ref = useRef(false);
  const transactionId = order?.identifier;

  useEffect(() => {
    if (transactionId) {
      checkoutDataDispatch({
        type: CheckoutActionTypes.UPDATE,
        payload: {
          transactionId,
          productDetails,
          order,
        },
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (checkoutData.transactionId !== null && !trackedPurchaseGA4Ref.current) {
      trackCheckout(EcommerceEvent.PURCHASE);

      trackedPurchaseGA4Ref.current = true;
    }
  }, [checkoutData.transactionId]);

  if (isLoadingUserProfile || isLoadingOrder || !productDetails) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Loader />
      </Flex>
    );
  }

  return (
    <SucceedSummaryView
      order={order}
      userProfile={userProfile}
      claimEnquiryUuid={claimEnquiryUuid}
      channel={channel}
      claimId={claimId}
      isError={isError}
      productTitle={productTitle}
      isUpgradeOffer={isUpgradeOffer}
      productDetails={productDetails}
    />
  );
};

export default Succeeded;
