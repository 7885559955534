import {
  Box,
  type BoxProps,
  Flex,
  type HTMLChakraProps,
  Text,
} from '@chakra-ui/react';
import { type ReactNode, useLayoutEffect, useRef, useState } from 'react';

const DEFAULT_CARD_WIDTH = 260;

// TODO: Move this to DS
export default function GiftCard({
  title = 'Giftcard',
  cardHolderHeader = 'Michelle Smith',
  cardHolderLabel = 'Card holder',
  expiryDateLabel = 'Expires',
  expiryDate = '12/27',
  cardNumber = '1234567890122313',
  cornerElement,
  uncoverCardNumber = false,
  ...props
}: BoxProps & {
  title?: string;
  cardHolderHeader?: string;
  cardHolderLabel?: string;
  expiryDateLabel?: string;
  expiryDate?: string;
  cardNumber?: string;
  cornerElement?: ReactNode;
  uncoverCardNumber?: boolean;
}) {
  const [isRtl, setIsRtl] = useState(
    () => document && document.documentElement.dir === 'rtl',
  );
  const containerRef = useRef<HTMLDivElement>();
  const paddingY = '6%';
  const paddingX = '7%';
  const cardNumberParts = cardNumber.match(/.{1,4}/g);

  const cardPinCharactersSpacing = '.3em';
  const labelSpacing = '.3em';

  const areContainerQueriesSupported = checkContainerQuerySupport();
  const width = props.w ?? props.width ?? DEFAULT_CARD_WIDTH;
  const [rootFontSize, setRootFontSize] = useState(() =>
    typeof width === 'number'
      ? `${width / 100}px`
      : `${(DEFAULT_CARD_WIDTH / 100).toFixed(2)}px`,
  );

  if (!cardNumberParts) {
    throw new Error('Card number must be a string of 16 digits');
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!areContainerQueriesSupported) {
        const containerWidth = containerRef.current?.clientWidth;
        if (containerWidth) {
          const em = containerWidth / 100;
          setRootFontSize(`${em.toFixed(2)}px`);
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize, { passive: true });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef.current?.clientWidth, areContainerQueriesSupported]);

  useLayoutEffect(() => {
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          setIsRtl(
            mutation.target instanceof HTMLElement &&
              mutation.target.dir === 'rtl',
          );
        }
      }
    });

    document &&
      observer.observe(document.documentElement, {
        attributes: true,
      });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      pos="relative"
      aspectRatio={1.7}
      borderRadius="4%/6%"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)"
      backdropFilter="blur(4px)"
      w={260}
      overflow="hidden"
      isolation="isolate"
      ref={containerRef}
      sx={{
        'container-type': 'inline-size',
      }}
      {...props}
    >
      <Box
        zIndex={2}
        pos="absolute"
        background="linear-gradient(to right, #FFF 0%, #FFF 30%, rgba(255, 255, 255, 0) 100%)"
        w="30%"
        h="120%"
        transform="translate(-50%, -50%) rotate(20deg)"
        filter="blur(10px)"
      />
      <AirHelpLogo
        zIndex={1}
        w="full"
        pos="absolute"
        left={0}
        top={0}
        transform="auto"
        translateX="-25%"
        translateY="-15%"
      />
      <Flex
        textAlign="start"
        direction="column"
        w="full"
        h="full"
        px={paddingX}
        py={paddingY}
        pos="relative"
        zIndex={5}
        fontSize={areContainerQueriesSupported ? '1cqw' : rootFontSize}
      >
        <Text fontSize="5.5em" fontWeight="bold" color="white">
          {title}
        </Text>
        <Box flex={1} />
        <Flex justifyContent="space-between">
          <Text
            color={isRtl ? 'primary.900' : 'white'}
            fontSize="5em"
            fontWeight="semibold"
            letterSpacing={cardPinCharactersSpacing}
          >
            {uncoverCardNumber ? cardNumberParts[0] : '••••'}
          </Text>
          <Text
            color={isRtl ? 'primary.900' : 'white'}
            fontWeight="semibold"
            fontSize="5em"
            letterSpacing={cardPinCharactersSpacing}
          >
            {uncoverCardNumber ? cardNumberParts[1] : '••••'}
          </Text>
          <Text
            color={isRtl ? 'white' : 'primary.900'}
            fontWeight="semibold"
            fontSize="5em"
            letterSpacing={cardPinCharactersSpacing}
          >
            {uncoverCardNumber ? cardNumberParts[2] : '••••'}
          </Text>
          <Text
            color={isRtl ? 'white' : 'primary.900'}
            fontWeight="semibold"
            fontSize="5em"
            letterSpacing={cardPinCharactersSpacing}
          >
            {cardNumberParts[3]}
          </Text>
        </Flex>
        <Box p="3.5em" />
        <Text
          fontSize="3.5em"
          mb={labelSpacing}
          fontWeight="bold"
          color={isRtl ? 'primary.900' : 'white'}
        >
          {cardHolderLabel}
        </Text>
        <Text
          fontSize="6em"
          fontWeight="bold"
          color={isRtl ? 'primary.900' : 'white'}
          lineHeight={1}
        >
          {cardHolderHeader}
        </Text>

        <Box
          pos="absolute"
          w="10%"
          top={0}
          right={0}
          marginTop={paddingY}
          marginRight={paddingX}
        >
          {cornerElement ? (
            cornerElement
          ) : (
            <AirHelpLogo w="full" opacity={0.9} />
          )}
        </Box>

        <Flex
          direction="column"
          pos="absolute"
          bottom={0}
          right={0}
          marginBottom={paddingY}
          marginRight={paddingX}
          textAlign="right"
        >
          <Text
            mb={labelSpacing}
            fontSize="3em"
            fontWeight="semibold"
            color={isRtl ? 'white' : 'primary.900'}
          >
            {expiryDateLabel}
          </Text>
          <Text
            fontSize="5em"
            fontWeight="semibold"
            lineHeight={1.1}
            color={isRtl ? 'white' : 'primary.900'}
          >
            {expiryDate}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

// TODO: Replace with DS logo when it will support sizing
export function AirHelpLogo(props: HTMLChakraProps<'svg'>) {
  return (
    <Box
      as="svg"
      aspectRatio={0.9}
      w={100}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3832 19.1084H2.6856C0.820771 19.1084 -0.471875 17.2767 0.163863 15.5698L5.165 2.22723C5.67359 0.895052 6.96624 0 8.40724 0C9.84825 0 11.1197 0.874241 11.6495 2.1856L16.8837 15.5282C17.5619 17.2558 16.2692 19.1084 14.3832 19.1084Z"
        fill="#0D73FF"
      />
      <path
        d="M14.3407 4.9124C13.811 4.9124 13.3024 5.03729 12.8574 5.24545L16.8837 15.5074C17.5618 17.235 16.2692 19.0876 14.3831 19.0876H6.60592L6.09735 20.4614C5.46161 22.1891 6.75426 24 8.61909 24H20.3167C22.2027 24 23.4954 22.1474 22.8173 20.4198L17.583 7.07719C17.0532 5.78665 15.7606 4.9124 14.3407 4.9124Z"
        fill="#FF6B73"
      />
      <path
        d="M12.8574 5.24545C12.0521 5.62012 11.4164 6.28617 11.0774 7.1396L6.60592 19.0876H14.3831C16.2692 19.0876 17.5618 17.235 16.8837 15.5074L12.8574 5.24545Z"
        fill="#0D3073"
      />
    </Box>
  );
}

function checkContainerQuerySupport() {
  if (typeof window === 'undefined' || typeof CSS === 'undefined') {
    return false;
  }

  return (
    CSS.supports('container-type', 'inline-size') &&
    CSS.supports('font-size', '1cqi')
  );
}
