import {
  Text,
  Flex,
  HStack,
  Box,
  VStack,
  WrapItem,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import {
  Airplane,
  BoxImportant,
  Law,
  Compensation,
  OpenInNew,
  AltArrowForward,
} from '@airhelp/icons';
import { Link } from 'react-router-dom';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';
import config from 'config';
import { useCompensationData } from '../useCompensationData';
import { Tooltip } from './Tooltip';

interface RowProps {
  freeOfCharge: boolean;
  label: string;
  value: string;
  icon: JSX.Element;
  tooltipTitle?: string;
  dataTestId?: string;
  tooltipDescription?: string;
  textStyle?: SystemStyleObject;
}

function Row({
  freeOfCharge,
  label,
  value,
  tooltipDescription,
  tooltipTitle,
  icon,
  dataTestId,
  textStyle,
}: RowProps) {
  const showTooltip = Boolean(tooltipTitle && tooltipDescription);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      data-testid={dataTestId}
    >
      <WrapItem alignItems="center" gap={1}>
        <Box
          width="40px"
          height="40px"
          rounded="full"
          backgroundColor="primary.100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          me={2}
        >
          {icon}
        </Box>
        <Text fontSize="sm" color="primary.900" sx={textStyle}>
          {label}
        </Text>
        {showTooltip ? (
          <Tooltip title={tooltipTitle} dataTestId={dataTestId}>
            {tooltipDescription}
          </Tooltip>
        ) : null}
      </WrapItem>
      {freeOfCharge ? (
        <Text fontSize="sm" color="success.500">
          No fees
        </Text>
      ) : (
        <Text fontSize="sm" color="primary.900" sx={textStyle}>
          {value}
        </Text>
      )}
    </HStack>
  );
}

export function Table() {
  const { id } = useParams();
  const { locale } = useLocale();

  const { legalFee, serviceFee, freeOfCharge, formattedValues } =
    useCompensationData({ claimId: Number(id) });

  const displayLegalFee = legalFee > 0 && !freeOfCharge;

  return (
    <Flex
      backgroundColor="greyscale.100"
      rounded="lg"
      w="100%"
      px={4}
      py={{
        base: 8,
        md: 0,
      }}
      flexDir="column"
      justifyContent="space-between"
      gap={8}
    >
      <VStack spacing={3}>
        <Row
          value={formattedValues.claimCompensation}
          label="Flight Compensation"
          freeOfCharge={false}
          icon={<Airplane color="primary.400" w={5} h={5} />}
        />

        <Row
          value={
            serviceFee > 0
              ? `- ${formattedValues.serviceFee}`
              : formattedValues.serviceFee
          }
          dataTestId="service-fee"
          label="Service fee"
          tooltipTitle="Service fee"
          tooltipDescription="We deduct a service fee to pay for our work to get your compensation."
          freeOfCharge={freeOfCharge}
          icon={<BoxImportant color="primary.400" w={5} h={5} />}
        />
        {displayLegalFee ? (
          <Row
            freeOfCharge={freeOfCharge}
            value={
              legalFee > 0
                ? `- ${formattedValues.legalFee}`
                : formattedValues.legalFee
            }
            icon={<Law color="primary.400" w={5} h={5} />}
            dataTestId="legal"
            label="Legal action fee"
            tooltipTitle="Legal fee"
            tooltipDescription="We only take legal fees for complicated cases that require our legal team’s support."
          />
        ) : null}
        <hr
          style={{
            width: '100%',
          }}
        />
        <Row
          icon={<Compensation color="primary.400" w={5} h={5} />}
          freeOfCharge={false}
          value={formattedValues.finalCompensation}
          label="Your Compensation"
          textStyle={{
            fontWeight: 'bold',
          }}
        />
      </VStack>
      <HStack
        justifyContent="space-between"
        as={Link}
        width="100%"
        rounded="12px"
        border="1px solid"
        borderColor="greyscale.400"
        p={6}
        cursor="pointer"
        to={config.pricingUrl.replace('{{locale}}', locale)}
        data-testid="compensation-fee-link"
      >
        <HStack spacing={0}>
          <OpenInNew w={4} h={4} me={5} />
          <Text fontSize="sm" fontWeight="medium" color="primary.900">
            Read more about our fees
          </Text>
        </HStack>

        <AltArrowForward
          color="greyscale.500"
          w={5}
          h={5}
          transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
        />
      </HStack>
    </Flex>
  );
}
