export const CLAIM_STATES = {
  ACCEPTED_AFTER_LEGAL_ACTION: 'accepted_after_legal_action',
  ACCEPTED_BY_AIRHELP: 'accepted_by_airhelp',
  ACCEPTED_BY_AIRLINE: 'accepted_by_airline',
  ACCEPTED_LATE_BY_AIRLINE: 'accepted_late_by_airline',
  CLOSED: 'closed',
  COMPENSATION_COLLECTED: 'compensation_collected',
  FEE_COLLECTED_FROM_CUSTOMER: 'fee_collected_from_customer',
  LEGAL_ACTION_STARTED: 'legal_action_started',
  LEGAL_ACTION_TAKEN: 'legal_action_taken',
  LOST_AFTER_LEGAL_ACTION: 'lost_after_legal_action',
  LEGALLY_VIABLE: 'legally_viable',
  LEGALLY_VIABLE_ON_HOLD: 'legally_viable_on_hold',
  NEW: 'new',
  PAYOUT_APPROVED: 'payout_approved',
  READY_FOR_LEGAL_ACTION: 'ready_for_legal_action',
  READY_FOR_LEGAL_ASSESSMENT: 'ready_for_legal_assessment',
  REJECTED_AFTER_LEGAL_ASSESSMENT: 'rejected_after_legal_assessment',
  REJECTED_BY_AIRHELP: 'rejected_by_airhelp',
  REJECTED_BY_AIRLINE: 'rejected_by_airline',
  REPROCESSED: 'reprocessed',
  RIGHTFULLY_REJECTED_BY_AIRLINE: 'rightfully_rejected_by_airline',
  SENDING_TO_AIRLINE: 'sending_to_airline',
  SENT_TO_AIRLINE: 'sent_to_airline',
  SENT_TO_PAYOUT: 'sent_to_payout',
  SERVICE_FEE_COLLECTED: 'service_fee_collected',
  SPECIAL_CARE_REQUIRED: 'special_care_required',
  WRONGFULLY_REJECTED_BY_AIRLINE: 'wrongfully_rejected_by_airline',
} as const;

export type ClaimState = (typeof CLAIM_STATES)[keyof typeof CLAIM_STATES];

export const CLAIM_FINAL_STATES = [
  CLAIM_STATES.CLOSED,
  CLAIM_STATES.REJECTED_AFTER_LEGAL_ASSESSMENT,
  CLAIM_STATES.REJECTED_BY_AIRHELP,
  CLAIM_STATES.RIGHTFULLY_REJECTED_BY_AIRLINE,
  CLAIM_STATES.LOST_AFTER_LEGAL_ACTION,
];

export type ClaimFinalState = (typeof CLAIM_FINAL_STATES)[number];

export const CLAIM_SUCCESSFUL_STATES = [
  CLAIM_STATES.SENT_TO_PAYOUT,
  CLAIM_STATES.PAYOUT_APPROVED,
  CLAIM_STATES.FEE_COLLECTED_FROM_CUSTOMER,
];

export type ClaimSuccessfulState = (typeof CLAIM_SUCCESSFUL_STATES)[number];

export const CLAIM_DETAILED_REJECTED_REASON_STATES = [
  CLAIM_STATES.REJECTED_AFTER_LEGAL_ASSESSMENT,
  CLAIM_STATES.REJECTED_BY_AIRHELP,
  CLAIM_STATES.RIGHTFULLY_REJECTED_BY_AIRLINE,
] as const;

export type ClaimDetailedRejectedReasonState =
  (typeof CLAIM_DETAILED_REJECTED_REASON_STATES)[number];

const CLAIM_DETAILED_REASON_STATES = [
  CLAIM_STATES.WRONGFULLY_REJECTED_BY_AIRLINE,
  ...CLAIM_FINAL_STATES,
] as const;

export type ClaimDetailedReasonState =
  (typeof CLAIM_DETAILED_REASON_STATES)[number];

export const CLAIM_LEGAL_STATES = [
  CLAIM_STATES.ACCEPTED_AFTER_LEGAL_ACTION,
  CLAIM_STATES.LEGAL_ACTION_STARTED,
  CLAIM_STATES.LEGAL_ACTION_TAKEN,
  CLAIM_STATES.LEGALLY_VIABLE,
  CLAIM_STATES.LEGALLY_VIABLE_ON_HOLD,
  CLAIM_STATES.LOST_AFTER_LEGAL_ACTION,
  CLAIM_STATES.READY_FOR_LEGAL_ACTION,
  CLAIM_STATES.REJECTED_AFTER_LEGAL_ASSESSMENT,
];

export type ClaimLegalState = (typeof CLAIM_LEGAL_STATES)[number];

export const CLAIM_COMPENSATION_COLLECTABLE_STATES = [
  CLAIM_STATES.ACCEPTED_BY_AIRLINE,
  CLAIM_STATES.ACCEPTED_LATE_BY_AIRLINE,
  CLAIM_STATES.ACCEPTED_AFTER_LEGAL_ACTION,
];

export type ClaimCompensationCollectableState =
  (typeof CLAIM_COMPENSATION_COLLECTABLE_STATES)[number];
