import {
  Box,
  TabList,
  Tab,
  Tabs,
  type StyleProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot/PulsatingNotificationDot';

export interface BaseChipItem {
  key: string;
  isActive: boolean;
  label: string;
  showNotification: boolean;
}

interface ChipGroupProps<T extends BaseChipItem> extends StyleProps {
  dataTestId: string;
  items: T[];
  onItemClick: (item: T) => void;
}

export default function ChipGroup<T extends BaseChipItem>({
  items,
  onItemClick,
  dataTestId,
  ...props
}: ChipGroupProps<T>) {
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const isFitted = useBreakpointValue({ base: true, lg: undefined });

  useEffect(() => {
    const activeItemIndex = items.findIndex((item) => item.isActive);
    if (activeItemIndex !== -1) {
      requestAnimationFrame(() => {
        itemRefs.current[activeItemIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      });
    }
  }, [items]);

  return (
    <Tabs
      {...props}
      isFitted={isFitted}
      index={items.findIndex((tab) => tab.isActive)}
      variant="soft-rounded"
      data-testid={dataTestId}
    >
      <TabList gap={2}>
        {items.map((item, index) => (
          <Tab
            key={`${dataTestId}-${item.key}`}
            ref={(el) => (itemRefs.current[index] = el)}
            bgColor="greyscale.300"
            color="greyscale.600"
            fontWeight="normal"
            border="2px solid transparent"
            boxSizing="border-box"
            p={2}
            fontSize="sm"
            _selected={{
              color: 'primary.900',
              fontWeight: 'medium',
              bgColor: 'greyscale.100',
              borderColor: 'transparent',
            }}
            _active={{
              borderColor: item.isActive ? '#DEE5ED' : 'transparent',
              bgColor: item.isActive ? 'greyscale.100' : 'greyscale.400',
              color: 'primary.900',
            }}
            sx={{
              WebkitTapHighlightColor: 'transparent',
              '@media (hover: hover)': {
                '&:hover': {
                  bgColor: item.isActive ? 'greyscale.100' : 'greyscale.400',
                  color: 'primary.900',
                  fontWeight: 'medium',
                  borderColor: item.isActive ? '#DEE5ED' : 'transparent',
                },
              },
            }}
            onClick={() => {
              onItemClick(item as unknown as T);
            }}
            data-testid={`${dataTestId}-${item.key}`}
          >
            {item.label}
            {item.showNotification ? (
              <Box position="relative">
                <PulsatingNotificationDot width="6px" height="6px" />
              </Box>
            ) : null}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
