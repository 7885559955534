import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router-dom';
import { AltArrowForward } from '@airhelp/icons';
import { Button, Flex, Text, Tag } from '@chakra-ui/react';
import { useTrackEvent } from 'utils/tracking/hooks';
import { PAGE_PATHS } from 'config/routes';
import { isRtl } from 'utils/textDirection/isRtl';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';

const TopBannerEssentialUpgrade: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { trackCtaClick } = useTrackEvent();

  const handleDiscoverButton = () => {
    trackCtaClick('essential to smart', 'top banner');
  };

  const showUpgradeButton = !location.pathname.includes(
    PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE,
  );

  return (
    <Flex
      bgColor="#0D7CFF"
      bg="linear-gradient(89deg, #0D7CFF 0%, #8C62FC 50%, #FF6B73 100%)"
      py={{ base: 0, md: 3 }}
      ps={{ base: 4, lg: 10 }}
      pe={{ base: 4, md: 4 }}
      alignItems="center"
      width="100%"
      minHeight={{ base: 12, md: 16 }}
      data-testid="essential-upgrade-top-banner"
    >
      <Flex
        width="100%"
        pe={{ base: 0, md: 3 }}
        alignItems="center"
        justifyContent="flex-start"
        justifySelf="flex-start"
      >
        <Tag
          size="xs"
          backgroundColor="success.500"
          color="greyscale.100"
          flexShrink="0"
          me={3}
          display={{ base: 'none', md: 'block' }}
          data-testid="essential-upgrade-badge"
        >
          {t('new_benefits_banner.just_for_you')}
        </Tag>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          lineHeight="short"
          color="greyscale.100"
          fontWeight="500"
          m={0}
        >
          {t('new_benefits_banner.unlock_new_benefits')}
          <Text
            as="span"
            ms={2}
            fontWeight="500"
            lineHeight="short"
            color="primary.200"
            fontSize={{ base: 'sm', md: 'md' }}
            display={{ base: 'none', md: 'initial' }}
          >
            {t('new_benefits_banner.get_lounge_access')}
          </Text>
        </Text>
      </Flex>
      {showUpgradeButton ? (
        <Button
          variant={{ base: 'outline', md: 'subtle' }}
          border={{ base: 'none !important', md: 'inherit' }}
          size={{ base: 'sm', md: 'md' }}
          as={ReactLink}
          to={{
            pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.SPECIAL_OFFER_PAGE),
          }}
          color={{
            base: 'greyscale.100 !important',
            md: 'primary.500 !important',
          }}
          width="auto"
          minWidth="unset !important"
          justifySelf="flex-end"
          justifyContent="flex-start"
          alignItems="center"
          iconSpacing={0}
          whiteSpace="nowrap"
          me={{ base: -2, md: 0, lg: 3 }}
          rightIcon={
            <AltArrowForward
              boxSize={7}
              display={{ base: 'block', md: 'none' }}
              color="greyscale.100"
              me={-3}
              transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
            />
          }
          onClick={handleDiscoverButton}
          sx={{ span: { mr: 0 } }}
          data-testid="essential-upgrade-top-banner-goto-page-btn"
          _focusVisible={{ outline: 'none' }}
          _hover={{
            bg: {
              base: 'transparent',
              md: 'greyscale.200',
            },
          }}
        >
          {t('new_benefits_banner.discover')}
        </Button>
      ) : null}
    </Flex>
  );
};

export default TopBannerEssentialUpgrade;
