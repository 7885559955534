import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVertical, Edit, DeleteBin } from '@airhelp/icons';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type Journey } from 'models/journey/Journey';
import Delete from './Delete';
import Rename from './Rename';

interface IComponent {
  journey: Journey;
}

const JourneyActions: React.FC<IComponent> = ({ journey }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const renameModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();

  const handleToggleRename = () => {
    renameModalDisclosure.onToggle();
  };

  const handleOpenDelete = () => {
    deleteModalDisclosure.onOpen();
    trackCtaClick('delete', 'trip options');
  };

  const handleCloseDelete = () => {
    deleteModalDisclosure.onClose();
  };

  const handleOptionClick = () => {
    trackCtaClick('options', 'trip view');
  };

  return (
    <>
      <Menu
        isLazy
        closeOnBlur
        closeOnSelect
        autoSelect={false}
        placement="bottom-end"
      >
        <MenuButton
          borderRadius="50%"
          color="primary.500"
          backgroundColor={{ base: 'greyscale.100', lg: 'transparent' }}
          width={9}
          height={9}
          me={-3}
          onClick={handleOptionClick}
          _hover={{
            cursor: 'pointer',
            lg: { bgColor: 'primary.200' },
            color: 'primary.600',
          }}
          _active={{ bgColor: 'primary.200', boxShadow: 'none' }}
          data-testid="toggle-option-journey"
        >
          <MoreVertical fontSize="xl" />
        </MenuButton>
        <MenuList py={0} borderRadius="md">
          <MenuItem
            py={3}
            borderRadius={7}
            icon={
              <Box
                borderRadius="md"
                borderWidth="1px"
                borderColor="greyscale.400"
                bg="white"
                p={1}
                me={-1}
              >
                <Edit boxSize={4} color="primary.500" />
              </Box>
            }
            role="menuitem"
            color="primary.900"
            fontWeight="medium"
            fontSize="sm"
            onClick={handleToggleRename}
            _hover={{
              backgroundColor: 'greyscale.300',
              textDecoration: 'none',
              color: 'greyscale.700',
            }}
            _focus={{ boxShadow: 'none' }}
            data-testid="option-journey-change-name"
          >
            {t('common.rename')}
          </MenuItem>
          <MenuItem
            py={3}
            borderRadius={7}
            icon={
              <Box
                borderRadius="md"
                borderWidth="1px"
                borderColor="greyscale.400"
                bg="white"
                p={1}
                me={-1}
              >
                <DeleteBin boxSize={4} color="primary.500" />
              </Box>
            }
            role="menuitem"
            color="primary.900"
            fontWeight="medium"
            fontSize="sm"
            onClick={handleOpenDelete}
            _hover={{
              backgroundColor: 'greyscale.300',
              textDecoration: 'none',
              color: 'greyscale.700',
            }}
            _focus={{ boxShadow: 'none' }}
            data-testid="option-journey-delete"
          >
            {t('common.delete')}
          </MenuItem>
        </MenuList>
      </Menu>
      <Rename
        journey={journey}
        handleToggleRename={handleToggleRename}
        isOpen={renameModalDisclosure.isOpen}
      />
      <Delete
        journey={journey}
        handleCloseDelete={handleCloseDelete}
        isOpen={deleteModalDisclosure.isOpen}
      />
    </>
  );
};

export default JourneyActions;
