import {
  Dk,
  De,
  Gb,
  Es,
  Gr,
  Fr,
  It,
  Nl,
  No,
  Pl,
  Br,
  Pt,
  Ru,
  Ro,
  Tr,
  Fi,
  Sa,
  Se,
  Cn,
} from '@airhelp/flags';
import { type IconType } from '@airhelp/icons';
import { type Locale as DatePickerLocale } from 'date-fns';
import {
  arSA,
  enGB,
  da,
  de,
  el,
  es,
  fi,
  fr,
  it,
  nb,
  nl,
  pl,
  ptBR,
  pt,
  ro,
  ru,
  tr,
  sv,
  zhCN,
} from 'date-fns/locale';

const arabicLangEnable =
  import.meta.env.VITE_FEATURES_ARABIC_LANG_ENABLE === 'true';

export interface LocaleObject {
  code: string;
  displayName: string;
  icon: IconType;
  rtl?: boolean;
  hidden?: boolean;
}

const baseSupportedLocales: Record<string, LocaleObject> = {
  da: {
    code: 'da',
    displayName: 'Dansk',
    icon: Dk,
  },
  de: {
    code: 'de',
    displayName: 'Deutsch',
    icon: De,
  },
  en: {
    code: 'en',
    displayName: 'English',
    icon: Gb,
  },
  es: {
    code: 'es',
    displayName: 'Español',
    icon: Es,
  },
  el: {
    code: 'el',
    displayName: 'Ελληνικά',
    icon: Gr,
  },
  fr: {
    code: 'fr',
    displayName: 'Français',
    icon: Fr,
  },
  it: {
    code: 'it',
    displayName: 'Italiano',
    icon: It,
  },
  nl: {
    code: 'nl',
    displayName: 'Nederlands',
    icon: Nl,
  },
  nb: {
    code: 'nb',
    displayName: 'Norsk',
    icon: No,
  },
  pl: {
    code: 'pl',
    displayName: 'Polski',
    icon: Pl,
  },
  'pt-BR': {
    code: 'pt-BR',
    displayName: 'Português (Brasil)',
    icon: Br,
  },
  pt: {
    code: 'pt',
    displayName: 'Português (Portugal)',
    icon: Pt,
  },
  ru: {
    code: 'ru',
    displayName: 'Русский',
    icon: Ru,
  },
  ro: {
    code: 'ro',
    displayName: 'Română',
    icon: Ro,
  },
  tr: {
    code: 'tr',
    displayName: 'Türkçe',
    icon: Tr,
  },
  fi: {
    code: 'fi',
    displayName: 'Suomi',
    icon: Fi,
  },
  sv: {
    code: 'sv',
    displayName: 'Svenska',
    icon: Se,
  },
  'zh-CN': {
    code: 'zh-CN',
    displayName: '简体中文',
    icon: Cn,
  },
};

const arabicLocales: Record<string, LocaleObject> = {
  'ar-SA': {
    code: 'ar-SA',
    displayName: 'العربية',
    icon: Sa,
    rtl: true,
    hidden: false,
  },
};

export const locales: Record<string, LocaleObject> = Object.freeze(
  arabicLangEnable
    ? Object.assign(arabicLocales, baseSupportedLocales)
    : baseSupportedLocales,
);

export type Locale = keyof typeof locales;

export const mapLocaleToDatePickerLocale = (
  locale: string,
): DatePickerLocale => {
  const localeToDatePickerLocale: Record<string, DatePickerLocale> = {
    da,
    de,
    el,
    es,
    fi,
    fr,
    it,
    nb,
    nl,
    pl,
    pt,
    ro,
    ru,
    tr,
    sv,
    'pt-BR': ptBR,
    en: enGB,
    'zh-CN': zhCN,
  };

  if (arabicLangEnable) {
    localeToDatePickerLocale['ar-SA'] = arSA;
  }

  return localeToDatePickerLocale[locale];
};

export const mapLocaleToFlagKey = (locale: Locale): string => {
  const localeToFlagKeyMap = {
    da: 'Da',
    de: 'De',
    en: 'EnGb',
    es: 'Es',
    el: 'El',
    fr: 'Fr',
    it: 'It',
    nl: 'Nl',
    nb: 'Nb',
    pl: 'Pl',
    'pt-BR': 'PtBr',
    pt: 'Pt',
    ru: 'Ru',
    ro: 'Ro',
    tr: 'Tr',
    fi: 'Fi',
    sv: 'Sv',
    'zh-CN': 'ZhCn',
  };

  if (arabicLangEnable) {
    localeToFlagKeyMap['ar-SA'] = 'ArSa';
  }

  return localeToFlagKeyMap[locale];
};

export const normalizeLocale = (locale: string): Locale => {
  for (const key in locales) {
    if (key === locale) {
      return locale;
    }
  }
  const defaultLocale = 'en';
  if (locale.startsWith(defaultLocale)) {
    return defaultLocale;
  }
  if (locale.startsWith('zh')) {
    return 'zh-CN' as Locale;
  }
  if (arabicLangEnable && locale.startsWith('ar')) {
    return 'ar-SA' as Locale;
  }
  return defaultLocale;
};

export const mapBrowserLanguageToLocale = (locale: string): Locale => {
  const supportedLocale: Record<string, Locale> = {
    da: 'da',
    'da-DA': 'da',
    de: 'de',
    'de-DE': 'de',
    el: 'el',
    'el-EL': 'el',
    es: 'es',
    'es-ES': 'es',
    fi: 'fi',
    'fi-FI': 'fi',
    fr: 'fr',
    'fr-FR': 'fr',
    it: 'it',
    'it-IT': 'it',
    nb: 'nb',
    'nb-NB': 'nb',
    nl: 'nl',
    'nl-NL': 'nl',
    ro: 'ro',
    'ro-RO': 'ro',
    ru: 'ru',
    'ru-RU': 'ru',
    sv: 'sv',
    'sv-SV': 'sv',
    tr: 'tr',
    'tr-TR': 'tr',
    pl: 'pl',
    'pl-PL': 'pl',
    pt: 'pt',
    'pt-PT': 'pt',
    'pt-br': 'pt-BR',
    'pt-BR': 'pt-BR',
    en: 'en',
    'en-US': 'en',
    'en-GB': 'en',
    zh: 'zh-CN',
    'zh-cn': 'zh-CN',
    'zh-CN': 'zh-CN',
    'zh-Hans-CN': 'zh-CN',
  };

  const arabicToLocale: Record<string, Locale> = {
    ar: 'ar',
    'ar-sa': 'ar-SA',
    'ar-SA': 'ar-SA',
  };

  const browserLanguageToLocale = arabicLangEnable
    ? Object.assign(arabicToLocale, supportedLocale)
    : supportedLocale;

  return browserLanguageToLocale[locale];
};

export const dateFnLocale = {
  'ar-SA': arSA,
  de,
  da,
  es,
  fi,
  fr,
  'en-GB': enGB,
  el,
  it,
  nl,
  nb,
  pl,
  pt,
  'pt-BR': ptBR,
  ro,
  ru,
  tr,
  sv,
  zh: 'zh-CN',
};

export const singleLanguage = (lang: string | undefined | string[]) => {
  return lang?.constructor === Array ? lang[0] : lang;
};

export const mapLocaleToVeriff = (lang: string) => {
  const veriffMapping = {
    'ar-SA': 'ar',
    'pt-BR': 'pt',
    pt: 'pt-pt',
    'zh-CN': 'zh',
  };
  return veriffMapping[lang] || lang;
};

export const getMappedLocale = (lang: string) => {
  const localeMapping = {
    'ar-SA': 'ar',
    'pt-BR': 'pt',
  };
  return localeMapping[lang] || lang;
};
